<template>
  <dashboard url-prefix="/employer">
    <div id="employer" class="accountdetails">
      <el-tabs v-model="activeTab" type="border-card">
        <el-tab-pane name="name-email" label="Name and Email">
          <name-and-email />
        </el-tab-pane>
        <el-tab-pane name="password" label="Password">
          <change-password provider="employer" />
        </el-tab-pane>
        <!-- <el-tab-pane name="company-information" label="Company Information">
          <div v-if="info.company" class="detail-form company-information">
            <p class="mb-3">Update your company information</p>
            <el-alert v-show="success"
                      description="Company information has been updated successfully."
                      type="success"
                      effect="dark"
                      :closable="true"
                      :show-icon="true">
            </el-alert>

            <el-alert v-show="error"
                      description="Something went wrong updating the company information. Please check."
                      type="error"
                      :closable="true"
                      :show-icon="true"
                      effect="dark"
                      style="width: 85%; position: absolute; right: 87px; top: 0;">
            </el-alert>
            <el-form  :model="info.company" :rules="rules" class="">
              <el-row :gutter="15">
                <el-col :xs="24" :md="8">
                  <p style="font-weight: normal; color: #606266; margin-top: 10px;margin-bottom: 10px;">Update your company logo</p>
                  <el-upload
                    class="avatar-uploader"
                    action="https://jsonplaceholder.typicode.com/posts/"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload">
                     <img v-if="info.company.logo" :src="url + '/' + info.company.logo" alt="..." class="rounded-circle w-50" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-col>
                <el-col :xs="24" :md="16">
                    <el-row type="flex" justify="center">
                      <el-col :xs="24">
                        <el-form-item label="Company Name" prop="company_name">
                          <el-input v-model="info.company.company_name" :rows="3"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row type="flex" justify="center">
                      <el-col :xs="24">
                        <el-form-item label="Description" prop="description">
                          <el-input type="textarea" v-model="info.company.description" :rows="3"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row type="flex" justify="center">
                      <el-col :xs="24">
                        <el-form-item label="Company URL" prop="company_url">
                          <el-input v-model="info.company.company_url"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>

                </el-col>
              </el-row>
              <el-row type="flex" justify="center">
                <el-col :xs="24">
                  <p class="mt-4 mb-2 orange-text bold"><i class="el-icon-office-building"></i> Company address</p>
                  <el-form-item label="Address 1" prop="address_1">
                    <el-input v-if="info.company" v-model="info.company.address_1"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" justify="center">
                <el-col :xs="24">
                  <el-form-item label="Address 2" prop="address_2">
                    <el-input v-model="info.company.address_2"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :xs="24" :sm="12">
                  <el-form-item label="State" prop="state">
                    <el-input v-model="info.company.state"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12">
                  <el-form-item label="Zip Code" prop="zip">
                    <el-input v-model="info.company.zip"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row type="flex" justify="center">
                <el-col :xs="24">
                  <el-form-item label="Country" prop="country">
                    <el-input v-model="info.company.country"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>

        </el-tab-pane> -->
        <!-- <el-tab-pane name="billing-information" label="Billing Information">
          <div v-if="info.billing_information" class="detail-form billing-information">
            <p class="mb-4">Update your billing information</p>
            <el-alert v-show="success"
                      description="Billing information has been updated successfully."
                      type="success"
                      effect="dark"
                      :closable="true"
                      :show-icon="true">
            </el-alert>
            <el-alert v-show="error"
                      description="Something went wrong updating the billing information. Please check."
                      type="error"
                      :closable="true"
                      :show-icon="true"
                      effect="dark"
                      style="width: 85%; position: absolute; right: 87px; top: 0;">
            </el-alert>
              <el-form :label-position="labelPosition" :model="info.billing_information" :rules="rules" class="mt-4">
                <el-row type="flex" justify="center">
                  <el-col :xs="24">
                    <p class="mt-0 mb-3 orange-text bold "><i class="el-icon-office-building"></i> Billing address</p>
                    <el-checkbox class="mb-3">Same as Company Address</el-checkbox>
                    <el-form-item label="Address 1" prop="address_1">
                      <el-input v-model="info.billing_information.address_1"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row type="flex" justify="center">
                  <el-col :xs="24">
                    <el-form-item label="Address 2" prop="address_2">
                      <el-input v-model="info.billing_information.address_2"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :xs="24" :sm="12">
                    <el-form-item label="State" prop="state">
                      <el-input v-model="info.billing_information.state"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12">
                    <el-form-item label="Zip Code" prop="zip">
                      <el-input v-model="info.billing_information.zip"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row type="flex" justify="center">
                  <el-col :xs="24">
                    <el-form-item label="Country" prop="country">
                      <el-input v-model="info.billing_information.country"></el-input>
                    </el-form-item>
                  </el-col>
            </el-form>
          </div>
        </el-tab-pane> -->
      </el-tabs>
    </div>
  </dashboard>
</template>

<script>
import store from '../../vuex/store'
import Dashboard from '~/components/Dashboard/Dashboard'
import NameAndEmail from '~/components/Employer/AccountDetails/NameAndEmail'
import ChangePassword from '~/components/ChangePassword'
// import EmployerAdditionalSidebar from '~/components/Employer/AdditionalSidebar'

export default {
  store,
  components: { Dashboard, NameAndEmail, ChangePassword },

  data () {
    return {
      hasError: 'has_error',
      activeTab: 'name-email',
      info: {},

    }
  },

  watch: {
    '$route.hash': function (newValue) {
      this.setActivePage(newValue)
    },
  },

  mounted () {
    const hash = this.$route.hash
    if (hash) {
      this.setActivePage(hash)
    }
  },

  methods: {
    setActivePage (page) {
      this.activeTab = page.replace('#', '')
    },
  },
}
</script>

<style lang="scss">
#employer.accountdetails .company-information, #employer.accountdetails .billing-information {
  .el-form-item__label {
    margin-bottom: 0;
  }
  .el-form-item {
    margin-bottom: 14px;
  }
}
#employer.accountdetails {
  .avatar-uploader {
    text-align: left;
  }
  .avatar-uploader-icon {
    width: 165px;
    height: 165px;
    line-height: 165px;
  }
}
.password-strength {
  padding:0;
  margin-left: 140px;
  margin-bottom: 22px;
}
.password-strength span {
  color: #8e8f92;
  font-size: 13px;
}
.password-strength ul {
  list-style-type: none;
  padding-left: 0;
  line-height: 1.5rem;
  font-size: 12px;
}
.password-strength .has_error {
  color: red;
}
.password-strength .validated {
  color: green;
}
.password-strength li {
  padding-left: 18px;
  position: relative;
}
.password-strength li:before {
  content: "\e79d";
  display: table;
  font-family: 'element-icons' !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  left: 0;
  line-height: 1;
  position: absolute;
  speak: none;
  text-transform: none;
  top: 7px;
  vertical-align: baseline;
}
.password-strength li.validated:before {
  content: "\e79c";
}
.el-input__inner {
    position: relative;
}
.el-icon-view {
    color: #409EFF;
    cursor: pointer;
    font-size: 18px;
    position: absolute;
    right: 12px;
    top: 12px;
}
#special-char.has_error {
  color: green;
}
#special-char.validated {
  color: green !important;
}
#special-char.has_error:before {
  content: "\e79c";
}
@media (max-width: 767px){
  .password-strength {
      margin-left: 0;
  }
}
</style>

<style lang="scss" scoped>
  .password-strength.accountdetails {
    margin-top:-15px;
    margin-left: 240px;
    margin-bottom: 22px;
  }
</style>
