<template>
  <div class="detail-form">
    <p class="mb-4">Update account details</p>
    <error-alert :errors="Employer.errors" />
    <el-form ref="updateProfile" label-position="left" label-width="100px" :model="employer" :rules="rules" class="mt-4">
      <el-row type="flex" justify="center">
        <el-col :xs="24" :md="18">
          <el-form-item label="First Name" prop="first_name">
            <el-input v-model="employer.first_name" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-col :xs="24" :md="18">
          <el-form-item label="Last Name" prop="last_name">
            <el-input v-model="employer.last_name" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-col :xs="24" :md="18">
          <el-form-item label="Email">
            <el-input v-model="employer.email" disabled />
          </el-form-item>
        </el-col>
      </el-row>
      <div class="text-right buttons pb-4">
        <el-button :disabled="Employer.saving" :loading="Employer.saving" type="success" @click="save">
          Save
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import Employer from '~/models/Employer'
import { maxLength, required } from '~/rules'
import { EMPLOYER } from '~/vuex/mutation_types'
import ErrorAlert from '~/components/ErrorAlert'

export default {
  components: { ErrorAlert },

  data () {
    return {
      Employer: new Employer('name-and-email'),
      rules: {
        first_name: [required('First Name'), maxLength('First Name')],
        last_name: [required('Last Name'), maxLength('Last Name')],
      },
    }
  },

  computed: {
    employer () {
      return this.Employer.data
    },
  },

  methods: {
    save () {
      // save
      this.Employer.save()
        // update profile in vuex
        .then(response => {
          this.$store.commit(EMPLOYER, response.data)
        })
    },
  },
}
</script>
