<template>
  <div class="detail-form">
    <p class="mb-4">Update password</p>
    <el-form ref="updatePassword" label-position="left" label-width="140px" :model="form" :rules="rules">
      <el-row type="flex" justify="center">
        <el-col :xs="24" :md="18">
          <el-form-item label="Old Password">
            <el-input id="old_password" v-model="form.old_password" type="password" prop="old_password" />
            <i class="el-icon-view" @click="showText('old_password')" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-col :xs="24" :md="18">
          <el-form-item label="New Password" prop="password">
            <el-input id="password" v-model="form.password" type="password" name="password" />
            <i class="el-icon-view" @click="showText('password')" />
          </el-form-item>
        </el-col>
      </el-row>
      <password-requirements :password="form.password" class="accountdetails" />
      <el-row type="flex" justify="center">
        <el-col :xs="24" :md="18">
          <el-form-item label="Re-type Password" prop="password_confirmation">
            <el-input id="password_confirmation" v-model="form.password_confirmation" type="password" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-col :xs="24" :md="18" class="text-right">
          <a href="/forgotpassword" class="forgot-link"><p class="small">Forgot your password?</p></a>
        </el-col>
      </el-row>
      <div class="text-right buttons pb-4">
        <el-alert
          v-if="
            error !== undefined &&
              error !== null &&
              error !== '' &&
              error.check_password !== undefined &&
              error.check_password
          "
          :description="error.check_password"
          type="error"
          :closable="true"
          :show-icon="true"
          effect="dark"
          style="width: 85%; position: absolute; right: 87px; top: 0;"
        />

        <el-alert
          v-if="success"
          description="Your password has been updated."
          type="success"
          :closable="true"
          :show-icon="true"
          effect="dark"
          style="width: 85%; position: absolute; right: 87px; top: 0;"
          @close="passwordSuccess(false)"
        />

        <el-button
          type="success"
          :disabled="status === 'loading'"
          @click="updatePasswordSubmission(), handleChangePassword({ info: form, provider, id })"
        >
          Save
          <i v-show="status == 'loading'" class="el-icon-loading" />
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import PasswordRequirements from '~/components/SignUp/PasswordRequirements'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import getUserId from '~/helpers/getUserId'
import { required } from '~/rules'

export default {
  components: { PasswordRequirements },

  props: {
    provider: {
      type: String,
      required: true,
    },
  },

  data () {
    return {
      form: {},
      rules: {
        password: [required('Password')],
        old_password: [required('Old Password')],
        password_confirmation: [{
          validator: (rule, value, callback) => {
            if (value === '') {
              return callback(new Error('Confirm password field is required'))
            }
            if (value !== null && value !== this.form.password) {
              return callback(new Error('Passwords do not match'))
            }
            return callback()
          },
          triggers: 'blur',
        }],
      },
    }
  },

  computed: {
    ...mapGetters({
      error: 'getEmployerErrResp',
      success: 'getEmployerSuccessResp',
      status: 'authStatus',
    }),

    id () {
      return getUserId()
    },
  },

  watch: {
    success (newValue) {
      // check if successBio became true
      if (newValue) {
        // automatically close it after 3 seconds
        setTimeout(() => this.passwordSuccess(false), 1500)
      }
    },
  },

  created () {
    this.resetForm()
  },

  methods: {
    ...mapActions({
      changePassword: 'changePassword',
    }),

    ...mapMutations(['passwordSuccess']),

    resetForm () {
      this.form = {
        old_password: '',
        password: '',
        password_confirmation: null,
      }
    },

    showText (id) {
      if (document.getElementById(id).type === 'password') {
        document.getElementById(id).type = 'text'
        return
      }
      document.getElementById(id).type = 'password'
    },

    handleChangePassword (d) {
      this.changePassword(d)
        .then((d) => {
          console.log('d', d)
          if (d.errors === undefined) {
            this.resetForm()
          }
        })
    },

    updatePasswordSubmission () {
      this.$refs.updatePassword.validate()
    },
  },
}
</script>
